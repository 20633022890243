export default {
  api: {
    //Dev
    // url: 'http://localhost/out-caribbean-api/api/',
    url: 'https://outcaribbean.com/api/',
    key: 'DwPp5xUmtnAtN3dqyfCF'
  },

  title: {
    pre: 'OUTCARIBBEAN',
  },
}