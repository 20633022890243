import React from 'react';

import '../styles/Navbar.css';
import '../styles/Responsive.css';

export default function Navbar() {
  return (
    <div className='nav-wrapper'>
        <div className='nav-logo-container'>
            <img src={process.env.PUBLIC_URL + '/assets/outcaribbean_logo.png'} className='nav-logo'/>
            <img src={process.env.PUBLIC_URL + '/assets/outcaribbean_logo_color.png'} className='nav-logo-alt'/>
        </div>
        <div className='nav-items-container'>

        </div>
    </div>
  )
}
