import {React, useState, useEffect} from 'react';
import '../styles/Construction.css';
import '../styles/Responsive.css';

import config from '../constants/config.js';

import axios from 'axios';

import { IoArrowForward, IoSend } from "react-icons/io5";

export default function Construction({ triggerToast }) {
  const [email, setEmail] = useState("");
  const [text, setText] = useState('');
  const fullText = "Hello Gorgeous,\n \n Thank you so much for your interest! We're excited to share that we're working on something special for LGBTQ+ travelers who dream of exploring the Caribbean.\n \n Join our mailing list to stay in the loop, and be the first to know when we launch.\n \n With love,\n Janice, Founder";

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      if (index < fullText.length) {
        setText(fullText.slice(0, index + 1));
        index++;
      } else {
        clearInterval(timer);
      }
    }, 70); // Adjust this value to change typing speed

    return () => clearInterval(timer);
  }, []);

  const submitEmail = () => {

    if(email != ''){

      axios.post(config.api.url + 'email/subscribe', {
        auth: config.api.key,
        email: email,
      }, {
        crossdomain: true,
        headers: {
          'api': config.api.key,
        }
      })
      .then((json) => {
        const { success, response, data } = json.data;

        if(success){
          
          setEmail("");
          triggerToast(response, true);

        }else{
          triggerToast("The email could not be submitted.", false);
        }
      })
      .catch(function (error) {

        console.log(error);

      });

    }else{
      triggerToast("Your input was empty.", false);
    }

  }

  return (
    <div className='construction-container'>
      {/* <div>
        <img src={process.env.PUBLIC_URL + '/assets/outcaribbean_logo_color.png'} className='construction-logo'/>
      </div> */}
      <div className='construction-text-container'>
        <div className='construction-text'>
          {text}
        </div>
      </div>  
      <div className='email-container'>
        
        {/* <div className='email-text'>Please sign up for our mailinglist.</div> */}
        <div className='email-input-container'>
          <input 
            type='email'
            className='email-input'
            placeholder='ENTER YOUR EMAIL'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className='email-btn' onClick={() => {submitEmail()}}>
            <IoArrowForward size={16} color='#bebebe' />
          </div>
        </div>
        
      </div>
    </div>
  )
}
